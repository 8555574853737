import Mission from './Mission'
import Numbe from './Numbe'
import AboutUs from '../LandingPage/AboutUs'
import React from 'react'
export default function AboutContent (props) {
  return <div>
      <AboutUs/>
      <Mission/>
        <Numbe/>
    </div>
}
